


































import {Component, Emit, Mixins, Prop} from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import XDialog from "../SimpleDialog.vue";
import XButton from "../SimpleButton.vue";
import { confirmCountersNewValueForm } from "@/assets/scripts/form-fields/main";
import AppApiMixin from "../mixins/AppApi.vue";

const { values, attrs, errors, validations } = confirmCountersNewValueForm;

@Component({
  components: { XDialog, XButton },
  mixins: [validationMixin],
  computed: {
    ...errors
  },
  validations,
})
class ModalDialogForIndication extends Mixins(AppApiMixin, XDialog) {
  @Prop({ required: true }) showDialog!: boolean;

  values = { counterType: "" };
  allFieldAttrs = {
    counterType: {
      type: "select",
      label: "",
      required: true,
      items: ['Отрицательный расход', 'Перекрутка счетчика'],
    },
  };

  @Emit()
  public onAgree(evt: { name: string; payload: null }) {
    const payload = this.values.counterType === 'Перекрутка счетчика' ? 1 : 0;
    this.values.counterType = '';
    this.$v.$reset();
    this.$emit(evt.name, { name: evt.name, payload });
  }

  @Emit()
  public onDisagree(evt: { name: string; payload: null }) {
    this.values.counterType = '';
    this.$v.$reset();
    this.$emit(evt.name, { name: evt.name });
  }
}
export default ModalDialogForIndication;
