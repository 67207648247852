import { required } from "./field-rules";
import { filial_items } from "@/lib/config";

export default [
  {
    name: "filial",
    attrs: {
      type: "select",
      label: "Филиал",
      required: true,
      items: filial_items,
    },
    rules: [required]
  },
  {
    name: "login",
    attrs: {
      type: "text",
      label: "Логин",
      required: true
    },
    rules: [required]
  }
];
