import {Route} from "vue-router";
import {NavigationGuardNext, RouteRecord} from "vue-router/types/router";
import {store} from "@/store/store";


function tryAuth(to: Route, from: Route, next: NavigationGuardNext) {
  const { loggedIn } = store.state.auth;

  if (!loggedIn) {
    return next({ name: "auth" });
  } else {
    return next({ name: "myContracts" });
  }
}

function checkAuth(to: Route, from: Route, next: NavigationGuardNext) {
  const {loggedIn} = store.state.auth;

  if (!loggedIn) {
    return next();
  } else {
    return next({name: "home"});
  }
}

// Показать ошибку доступа к разделу приложения.
function showForbiddenAccessError(): void {
  store.commit(
    "error/setMessage",
    [
      "У вас отсутствует доступ к данному разделу приложения.",
      "Пожалуйста, используйте для навигации интерфейс приложения"
    ].join(" ")
  );
  store.commit("error/setShow", true);
}

// Защитник от прямого перехода по маршрутам "/contracts/*".
function checkContractId(to: Route, _: Route, next: NavigationGuardNext) {
  const currentContractId = store.getters["contract/id"];
  const contractId = parseInt(to.params.contractId);

  if (currentContractId === contractId) return next();

  showForbiddenAccessError();
  next({ name: "myContracts" });
}

// Защитник от прямого перехода по маршрутам "/companies/*".
function checkCompanyId(to: Route, _: Route, next: NavigationGuardNext) {
  const currentCompanyId = store.getters["organization/id"];
  const companyId = parseInt(to.params.companyId);

  if (currentCompanyId === companyId) return next();

  showForbiddenAccessError();
  next({ name: "myContracts" });
}

/**
 * Определить свойства маршрута на основании состояния приложения.
 * @param routeRecords массив, элементами которого являются объекты, представляющие
 * маршруты, совпадающие с маршрутом, по которому пытается перейти пользователь.
 * @returns undefined или объект, представляющий свойства маршрута, на который
 * пытается перейти пользователь.
 */
function getRouteAccessPropsByAppState(routeRecords: RouteRecord[]) {
  const routesByAppState = [
    {
      name: "hourlyConsumption",
      checkAccess: () =>
        !!(
          store.state.appEnvironment.constants &&
          store.state.appEnvironment.constants["ЛКЮЛПОЧАС"]
        )
    },
    {
      name: "myContracts",
      checkAccess: () => !store.getters["user/passwordChangeRequired"],
      toByRouteName: "accountSettings"
    },
    {
      name: "payments",
      checkAccess: () =>
        !!(
          store.state.appEnvironment.constants &&
          store.state.appEnvironment.constants["ЛКЮЛОПЛАТА"]
        )
    },
    {
      name: "appealsByDogovor",
      checkAccess: () =>
        !(
            store.state.appEnvironment.constants &&
            store.state.appEnvironment.constants["ЛКОТКЛОБР"]
        )
    },
  ];

  return routesByAppState.find(({name}) =>
      routeRecords.some(record => record.name === name)
  );
}

// Функция-обработчик перехода по любому маршруту, используется в хуке beforeEach.
function checkUserAccess(
  to: Route,
  from: Route,
  next: NavigationGuardNext
): void {
  const matchedRouteRecords: RouteRecord[] = to.matched;

  // Не удалось определить маршрут.
  if (!matchedRouteRecords.length) return next({ name: "pageNotFound" });

  if(store.getters["notifications/unreadNotificationsCount"] > 0) {
    if(['notificationsByContract', 'notification'].indexOf(to.name) < 0) {
      return next({
        name: "notificationsByContract",
        params: {
          contractId: store.getters["contract/id"],
          companyId: store.getters["organization/id"],
          message: 'Для продолжения работы с договором прочтите все уведомления.'
        }
      });
    }
  }

  // проверка доступности при явном указании
  const isDisabledRoute = matchedRouteRecords.some(
      record => record.meta.disabled
  );
  if (isDisabledRoute) {
    return next({ name: "pageNotFound" });
  }

  const isProtectedRoute = matchedRouteRecords.some(
    record => record.meta.requiresAuth
  );

  // Пропустить пользователя в случае, если маршрут не требует авторизации.
  if (!isProtectedRoute) return next();

  // Перенаправить неавторизованного пользователя на стр. "Авторизация" в случае
  // попытки прямого перехода по маршруту, требующему авторизации.
  if (!store.state.auth.loggedIn) return next({ name: "auth" });

  // Проверить доступ пользователя к данному разделу приложения, руководствуясь
  // состоянием приложения (хранилище vuex).
  const routeAccessProps = getRouteAccessPropsByAppState(matchedRouteRecords);

  if (routeAccessProps && !routeAccessProps.checkAccess()) {
    showForbiddenAccessError();

    return next({
      name: routeAccessProps.toByRouteName || "myContracts"
    });
  }

  const isRouteWithoutUsingContract = matchedRouteRecords.some(
    (record: RouteRecord) =>
      ["myContracts", "accountSettings"].includes(record.name || "")
  );

  // Перенаправить авторизованного пользователя на стр. "Мои договоры" в случае
  // попытки прямого перехода по маршруту, условием доступа к которому является
  // наличие выбранного договора.
  if (!isRouteWithoutUsingContract && !store.getters["contract/useContract"]) {
    return next({ name: "myContracts" });
  }

  return next();
}

export { checkCompanyId, checkContractId, checkUserAccess, tryAuth, checkAuth };
